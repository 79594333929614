// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-template-ci-fr-js": () => import("./../../../src/template/ci-fr.js" /* webpackChunkName: "component---src-template-ci-fr-js" */),
  "component---src-template-ci-gdpr-en-js": () => import("./../../../src/template/ci-gdpr-en.js" /* webpackChunkName: "component---src-template-ci-gdpr-en-js" */),
  "component---src-template-ci-gdpr-fr-js": () => import("./../../../src/template/ci-gdpr-fr.js" /* webpackChunkName: "component---src-template-ci-gdpr-fr-js" */),
  "component---src-template-ci-legal-en-js": () => import("./../../../src/template/ci-legal-en.js" /* webpackChunkName: "component---src-template-ci-legal-en-js" */),
  "component---src-template-ci-legal-fr-js": () => import("./../../../src/template/ci-legal-fr.js" /* webpackChunkName: "component---src-template-ci-legal-fr-js" */),
  "component---src-template-ci-questionnaire-fr-js": () => import("./../../../src/template/ci-questionnaire-fr.js" /* webpackChunkName: "component---src-template-ci-questionnaire-fr-js" */),
  "component---src-template-ci-questionnaire-us-js": () => import("./../../../src/template/ci-questionnaire-us.js" /* webpackChunkName: "component---src-template-ci-questionnaire-us-js" */),
  "component---src-template-ci-resultat-fr-js": () => import("./../../../src/template/ci-resultat-fr.js" /* webpackChunkName: "component---src-template-ci-resultat-fr-js" */),
  "component---src-template-ci-resultat-us-js": () => import("./../../../src/template/ci-resultat-us.js" /* webpackChunkName: "component---src-template-ci-resultat-us-js" */),
  "component---src-template-ci-us-js": () => import("./../../../src/template/ci-us.js" /* webpackChunkName: "component---src-template-ci-us-js" */)
}

